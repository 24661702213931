import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Container from "@mui/material/Container";
import { useOnboarding } from "../../../contexts/onboarding";

import { ReactComponent as AddIcon } from "../../../assets/Icons/add.svg";
import { useCommon } from "../../../contexts/common";
import Grid from "@mui/material/Grid";
import "./style.css";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { additionalEducationSchema } from "../../../validations/registerValidation";
import EducationForm from "./EducationForm";
import { useAlert } from "../../../contexts/Alert";



const Education = () => {
  const { next, onboardingState, setOnboardingState, updateOnboardingState } = useOnboarding();
  const { fetchDegreesList, degreesList } = useCommon();
  const [selectedDegrees, setSelectedDegree] = useState([]);

  const initialState = {
    professional_degree_id: null,
    degree_year: "",
    college_name: "",
    practice_year: "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    trigger,
    setValue
  } = useForm({
    resolver: zodResolver(additionalEducationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      degrees: onboardingState?.degrees || [initialState]
    }
  });

  useEffect(() => {
    fetchDegreesList();
    if (!onboardingState.degrees?.length) {
      updateOnboardingState({ degrees: [initialState] });
    }
  }, []);

  useEffect(() => {
    reset({
      degrees: onboardingState?.degrees || [initialState],
    });
  }, [onboardingState]);

  useEffect(() => {
    const selectedDegreeIds = onboardingState.degrees?.map(
      (degree) => degree.professional_degree_id
    );
    setSelectedDegree(selectedDegreeIds);
  }, [onboardingState.degrees, degreesList]);


  const handleChange = async (selected, index) => {
    const { name, value } = selected.target;
    const slicedName = name.split('.');
    const updatedDegrees = onboardingState.degrees.map((degree, i) =>
      i === index ? { ...degree, [slicedName[2]]: value } : degree
    );

    updateOnboardingState({
      degrees: updatedDegrees,
    });
    setValue(`degrees.${index}.${slicedName[2]}`, value);
    await trigger();
  };

  const handleDeleteDegree = (indexToDelete) => {
    const updatedDegrees = onboardingState.degrees.filter(
      (_, index) => index !== indexToDelete
    );
    setOnboardingState((prevState) => ({
      ...prevState,
      degrees: updatedDegrees,
    }));
  };

  const onSubmit = () => {
    if (isValid) {
      next();
    } else {
      trigger(["professional_degree_id", "degree_year", "practice_year", "college_name"]);   
    }
  };

  const addNew = async () => {
    if (onboardingState.degrees?.length !== 0) {
      const isTriggered = await trigger(["professional_degree_id", "degree_year", "practice_year", "college_name"]);
      if (isValid && isTriggered) {
        setOnboardingState((prevState) => ({
          ...prevState,
          degrees: [...(prevState.degrees), { ...initialState }],
        }));
      } else {
        await trigger();
      }
    }
  }

  return (
    <Container maxWidth="md">
      <Box>
        <div className="education">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="h4" mb={1} fontWeight={600}>
                Education
              </Typography>
              <Typography variant="body1" mb={3}>
                Where did you go to school, and when did you begin your
                practice?
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {onboardingState.degrees?.map((degree, index) =>
                <EducationForm
                  key={index}
                  degreesList={degreesList}
                  selectedDegrees={selectedDegrees}
                  onChange={(selected) => handleChange(selected, index)}
                  onDelete={() => handleDeleteDegree(index)}
                  showDeleteButton={onboardingState.degrees.length > 1}
                  control={control}
                  degree={degree}
                  errors={errors}
                  index={index}
                />
              )}
            </Grid>
          </Grid>
          <Button
            sx={{
              color: "primary.main",
              p: 1.125,
              minWidth: 0,
              mt: 1.5,
              mb: 0.5,
            }}
            onClick={addNew}
            variant="outlined"
          >
            <AddIcon width={18} height={18} />
            {onboardingState?.degrees?.length != 0
              ? "Add New Education"
              : "Add Education"}{" "}
          </Button>
        </div>
      </Box>
      <div className="stickyButton">
        <Box textAlign="center">
          <Button
            className="btnContinue"
            variant="contained"
            sx={{ minWidth: 212 }}
            onClick={(e) => handleSubmit(onSubmit)(e)}
          >
            Continue
          </Button>
        </Box>
      </div>
    </Container>
  )
};
export default Education;