import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { ReactComponent as CloseIcon } from "../../assets/Icons/close.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import { validateText, validateTitle, validateUrl } from "../../helper/Validations";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 622,
  p: 0,
  overflow: 'hidden',
};

function LinkModal({ open, onClose, addLink }) {
  const initialState = {
    label: '',
    url: '',
  }
  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);

  function handleClose() {
    onClose();
    setState(initialState);
    setIsInvalid(false);
  }

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  function isValid() {
    return validateTitle(state.label)
      && validateUrl(state.url)
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid()) {
      const link = { ...state, url: state.url.startsWith('http') ? state.url : `https://${state.url}`}
      addLink(link)
      handleClose();
    } else {
      setIsInvalid(true);
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pt: 3,
            px: 3,
            pb: 2
          }}
        >

          <Typography variant='h3'>Add Link</Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              p: 0.25,
              color: 'border.dark'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          component='form'
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            px: 3,
            pb: 3
          }}
        >
          <TextField
            label='Label'
            name='label'
            onChange={handleInput}
            value={state.label}
            error={isInvalid && !validateTitle(state.label)}
            helperText={
              isInvalid
              && !validateTitle(state.label)
              && "Please enter valid label"
            }
          />
          <TextField
            label='URL'
            name='url'
            onChange={handleInput}
            value={state.url}
            error={isInvalid && !validateUrl(state.url)}
            helperText={
              isInvalid
              && !validateUrl(state.url)
              && "Please enter valid url"
            }
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <LoadingButton
              variant="contained"
              sx={{
                minWidth: 218
              }}
              type='submit'
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}

export default LinkModal;