import React, { useEffect, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import PhoneNumberInput from "../../PhoneNumberInput";
import PlaceAutoComplete from "../../PlaceAutoComplete";
import { TextField } from "@mui/material";

import { useOnboarding } from "../../../contexts/onboarding";
import { ReactComponent as ChevronDown } from "../../../assets/Icons/chevron-down.svg";
import { useCommon } from "../../../contexts/common";
import "./style.css";
import { basicInfoValidationSchema } from "../../../validations/registerValidation";
import { formatUrl } from "../../../utils/helper";


const BasicInfo = () => {
  const { next, onboardingState, updateOnboardingState } = useOnboarding();
  const { statesList, fetchStatesList } = useCommon();
  const [countryState, setCountryState] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger
  } = useForm({
    resolver: zodResolver(basicInfoValidationSchema),
    mode: "onChange",
    defaultValues: {
      first_name: onboardingState.first_name || "",
      last_name: onboardingState.last_name || "",
      phone_number: onboardingState.phone_number || "",
      website_link: onboardingState.website_link || "",
      address1: onboardingState.address1 || "",
      city: onboardingState.city || "",
      state: onboardingState.state || 0,
      zip_code: onboardingState.zip_code || "",
    },
  });

  
  const formValues = useWatch({ control });
  
  useEffect(() => {
    fetchStatesList();
  }, []);
  
  useEffect(() => {
    updateOnboardingState(formValues);
  }, [formValues]);

  useEffect(() => {
    const updateState = async () => {
      if (countryState) {
        const stateId = statesList.find((st) => st.name === countryState)?.id;
        setValue("state", stateId);
        await trigger();
      }  
    }
    updateState();
  }, [countryState]);

  
  const handlePhoneNumberChange = (formattedNumber) => {
    setValue("phone_number", formattedNumber.phone_number);
  };

  const onSubmit = () => {
    if (isValid) {
      next();
    }
  };

  const handleAddressChange = async (address) => {
    const streetAddress = address?.location?.split(",")[0] || '';
    setCountryState(address?.state);
    setValue("address1", streetAddress);
    setValue("city", address.city);
    setValue("zip_code", address.zipCode);
    await trigger();
  };

  return (
    <Container maxWidth="md">
      <div className="cardShadow">
        <Typography variant="h5" mb={1.5}>
          Basic Information
        </Typography>

        <Grid container spacing={1.5} pb={3}>
          <Grid item sx={{ width: "100%" }} md={6}>
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  label="First Name"
                  fullWidth
                  error={!!errors.first_name}
                  helperText={errors.first_name?.message}
                />
              )}
            />
          </Grid>
          
          <Grid item sx={{ width: "100%" }} md={6}>
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  label="Last Name"
                  fullWidth
                  error={!!errors.last_name}
                  helperText={errors.last_name?.message}
                />
              )}
            />
          </Grid>

          <Grid item sx={{ width: "100%" }} md={6}>
            <PhoneNumberInput
              fullWidth
              variant="outlined"
              sx={{ mb: 3 }}
              value={onboardingState.phone_number || ""}
              onChange={handlePhoneNumberChange}
              error={!!errors.phone_number}
              helperText={errors.phone_number?.message}
              name='phone_number'
              label='Phone number'
              control={control}
              trigger={trigger}
            />

          </Grid>

          <Grid item sx={{ width: "100%" }} md={6}>
            <Controller
              name="website_link"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  label="Website Link"
                  fullWidth
                  error={!!errors.website_link}
                  helperText={errors.website_link?.message}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === "" || value === `https://`) {
                      field.onChange("");
                      return;
                    }
                    const formattedValue = formatUrl(value);
                    field.onChange(formattedValue);}}
                />
              )}
            />
          </Grid>
        </Grid>

        <Typography variant="h5" mb={1.5}>
          Add your Address
        </Typography>

        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <PlaceAutoComplete
              name="address1"
              variant="outlined"
              label="Street address"
              fullWidth
              onChange={handleAddressChange}
              error={!!errors.address1}
              helperText={errors.address1?.message}
              control={control}
            />
          </Grid>
          <Grid item sx={{ width: "100%" }} md={4}>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  variant="outlined"
                  label="State"
                  fullWidth
                  error={!!errors.state}
                  helperText={errors.state?.message}
                  SelectProps={{
                    IconComponent: () => (
                      <Box
                        sx={{
                          minWidth: 24,
                          width: 24,
                          height: 24,
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mr: 2,
                        }}
                      >
                        <ChevronDown width={12} height={6} />
                      </Box>
                    ),
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 220,
                          width: 250,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value={0} disabled>
                    Select a state
                  </MenuItem>
                  {statesList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sx={{ width: "100%" }} md={4}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  label="City"
                  fullWidth
                  error={!!errors.city}
                  helperText={errors.city?.message}
                />
              )}
            />
          </Grid>
          <Grid item sx={{ width: "100%" }} md={4}>
            <Controller
              name="zip_code"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  label="ZIP code"
                  fullWidth
                  error={!!errors.zip_code}
                  helperText={errors.zip_code?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </div>

      <div className="stickyButton">
        <Box textAlign="center">
          <Button
            className="btnContinue"
            variant="contained"
            sx={{ minWidth: 212 }}
            onClick={(e) => handleSubmit(onSubmit)(e)}
          >
            Continue
          </Button>
        </Box>
      </div>
    </Container>
  );
};

export default BasicInfo;
