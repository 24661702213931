export const convertObjectToFormData = (obj) => {
  const formData = new FormData();

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(`${key}[]`, item);
      });
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

export const formatUrl = (url) => {
  if (!url) return "";
  url = url.trim();
  if (!/^https?:\/\//i.test(url)) {
    url = `https://${url}`;
  }
  return url;
};
